import React, { useEffect, useRef } from "react";
import Rating from "react-rating";
import PlayerControl from "./PlayerControl";
import PlayerDetail from "./PlayerDetail";
import SongProgressBar from "./SongProgressBar";

function Player(songs) {
  const {
    islistened,
    isPlaying,
    updatePlayingIndex,
    PlayingId,
    isRanking,
    getrank,
    setRank,
    songId,
    isPlayedSong,
    selectedId,
    unselectSong,
    judgeRank,
  } = songs;
  const audioEl = useRef(null);
  useEffect(() => {
    if (isPlaying) {
      audioEl.current.play();
    } else {
      audioEl.current.pause();
    }
  }, [isPlaying]);

  const handleRatingChange = (value) => {
    // Handle the rating change here
    getrank({ ...getrank, rank: value, songId: songId });
  };
  console.log(islistened, "islistened");
  return (
    <>
      <div className="c-player">
        <audio src={songs.audio} ref={audioEl} />
        <div className="row">
          <div className="col-md-6">
            {setRank && (
              <span className="badge bg-primary me-2">
                Rank{" "}
                {setRank === 9
                  ? 1
                  : setRank === 8
                  ? 2
                  : setRank === 7
                  ? 3
                  : setRank === 6
                  ? 4
                  : setRank === 5
                  ? 5
                  : setRank === 4
                  ? 6
                  : setRank === 3
                  ? 7
                  : setRank === 2
                  ? 8
                  : setRank === 1
                  ? 9
                  : setRank}
              </span>
            )}
            <span className="badge bg-warning me-2">
              Judge Rank{" "}
              {judgeRank === 9
                ? 1
                : judgeRank === 8
                ? 2
                : judgeRank === 7
                ? 3
                : judgeRank === 6
                ? 4
                : judgeRank === 5
                ? 5
                : judgeRank === 4
                ? 6
                : judgeRank === 3
                ? 7
                : judgeRank === 2
                ? 8
                : judgeRank === 1
                ? 9
                : judgeRank}
            </span>
            {islistened === 0 ? (
              <span
                onClick={() => isPlayedSong(songId)}
                className="badge bg-danger me-2"
              >
                Not Listened
              </span>
            ) : (
              <span
                onClick={() => isPlayedSong(songId)}
                className="badge bg-success me-2"
              >
                Listened
              </span>
            )}

            <PlayerDetail song={songs} />
          </div>
          <div className="col-md-6">
            <PlayerControl
              isPlaying={isPlaying}
              PlayingId={PlayingId}
              setIsPlaying={updatePlayingIndex}
              unselectSong={unselectSong}
            >
              {setRank && (
                <button
                  className="play-btn bg-danger z-1"
                  onClick={() => unselectSong(selectedId)}
                >
                  <i className="fa fa-trash" />
                </button>
                // <p >unSelect</p>
              )}
            </PlayerControl>
            {isRanking && (
              <div className="float-end">
                <Rating
                  initialRating={setRank}
                  emptySymbol="fa fa-heart fs-2 me-2 "
                  fullSymbol="fa fa-heart fs-2 me-2 text-danger"
                  onChange={handleRatingChange}
                  stop={9}
                />
              </div>
            )}
          </div>
        </div>
        <SongProgressBar audioEl={audioEl} />
      </div>
    </>
  );
}

export default Player;
