/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
//** Import Image */
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";
import PageTitle from "../../../layouts/PageTitle";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";

const AppProfile = () => {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const onChange = (setState) => (e) => {
    setState(e.target.value);
  };
  const UserDetails = useSelector((state) => state.auth.auth);

  const ref = useRef();

  const onClick = () => {
    ref.current.click();
  };

  const imgUpload = (image) => {
    setLoader(true);
    const formdata = new FormData();
    formdata?.append("image", image[0]);
    axiosInstance
      .post(
        `super_judge/super_judges/update_image/${UserDetails.localId}`,
        formdata
      )
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);

        console.log(errors, "errors");
      });
  };
  const DeleteImg = () => {
    axiosInstance
      .delete(`super_judge/super_judges/delete_image/${UserDetails.localId}`)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
        }
      })
      .catch((errors) => {
        console.log(errors, "errors");
      });
  };

  const userData = () => {
    setLoader(true);
    axiosInstance
      .get(`super_judge/super_judges/${UserDetails.localId}`)
      .then((response) => {
        console.log(response);
        const Data = response.data?.data;
        if (response.data?.status === true) {
          setData(Data);
          dispatch(loginConfirmedAction(response?.data));
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);
        console.log(errors);
      });
  };
  useEffect(() => {
    userData();
  }, []);
  const viewPicture = () => {
    window.open(data?.image, "_blank");
  };
  const onUpdate = () => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("lname", lname ? lname : data?.lname);
    urlencoded.append("fname", fname ? fname : data?.fname);
    urlencoded.append("email", email ? email : data?.email);
    urlencoded.append("password", password);
    axiosInstance
      .put(`super_judge/super_judges/${UserDetails.localId}`, urlencoded)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      //   variant="primary"
                      className="bg-transparent border-0"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <img
                        src={data?.image}
                        className=" rounded-circle"
                        alt="profile"
                        height={87}
                        width={87}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={viewPicture}
                      >
                        <i className="fa fa-user-circle text-primary me-2" />
                        View Profile Picture
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={onClick}
                      >
                        <i className="fa fa-plus text-primary me-2" />
                        Add New Profile Picture
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={DeleteImg}
                      >
                        <i className="fa fa-trash text-danger me-2" />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <input
                  type="file"
                  ref={ref}
                  className="d-none"
                  onChange={(e) => {
                    imgUpload(e.target.files);
                  }}
                />
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0 text-capitalize">
                      {`${data?.fname} ${data?.lname}`}
                    </h4>
                    <p>{UserDetails.professionalType}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{data?.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <h4 className="text-primary">Account Setting</h4>
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">Email</label>
                                <input
                                  defaultValue={data?.email}
                                  type="email"
                                  placeholder="Email"
                                  className="form-control"
                                  onChange={onChange(setEmail)}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">
                                  New Password
                                </label>
                                <input
                                  type="password"
                                  placeholder="Password"
                                  className="form-control"
                                  onChange={onChange(setPassword)}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">First Name</label>
                                <input
                                  defaultValue={data?.fname}
                                  type="text"
                                  placeholder="1234 Main St"
                                  className="form-control"
                                  onChange={onChange(setFname)}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.lname}
                                  onChange={onChange(setLname)}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary w-50 mt-5"
                                    onClick={onUpdate}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
