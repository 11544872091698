import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../services/AxiosInstance";
import PlayerIndex from "./Player";

const JudgesSong = () => {
  const { id } = useParams();
  const { event } = useParams();
  const [loader, setLoader] = useState(false);
  const [keys, setKeys] = useState([]);
  const [selectedkeys, setSelectedKeys] = useState(null);
  const [data, setData] = useState([]);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [showPublish, setShowPublish] = useState(false);
  const [rank, setRank] = useState(null);

  const onshowPublish = () => setShowPublish(true);
  const onHidePublish = () => setShowPublish(false);

  const AssignSong = useCallback(() => {
    setLoader(true);
    axiosInstance
      .get(`super_judge/events/${id}/songs`)
      .then(function (response) {
        console.log(response, "Getting Companies");
        if (response?.data.status === true) {
          setData(response?.data?.data);
          setKeys(Object.keys(response.data.data));
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  }, [id]);

  const selectSong = useCallback(() => {
    const formdata = new FormData();
    formdata.append("rank", rank?.rank);
    setLoader(true);
    try {
      axiosInstance
        .post(`super_judge/songs/mark_selected/${rank?.songId}`, formdata)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            setRank(null);
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }, [AssignSong, rank?.rank, rank?.songId]);
  useEffect(() => {
    if (rank !== null) selectSong();
  }, [rank, selectSong]);
  const unselectSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .delete(`super_judge/songs/unmark_selected/${id}`)
        .then((response) => {
          console.log(response, "unSelected");
          if (response.data.status === true) {
            AssignSong();
            toast.success("UnSelected");
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.error);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const Publish = () => {
    setLoader(true);
    try {
      axiosInstance
        .put(`super_judge/events/${id}/publish_list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            onHidePublish();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const isPlayedSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .put(`super_judge/songs/mark_as_played_unplayed/${id}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            setRank(null);
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    AssignSong();
  }, [AssignSong]);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <div class="row mb-3">
          <div className="col-md-4">
            <select
              className="form-control"
              onChange={(e) => setSelectedKeys(e.target.value)}
            >
              <option selected disabled>
                Select Queue..
              </option>
              {keys && keys.filter((s) => s.split("_")[1] === "Hard Rock") && (
                <optgroup label={"Hard Rock"}>
                  {keys &&
                    keys
                      .filter((s) => s.split("_")[1] === "Hard Rock")
                      .map((e, i) => {
                        return (
                          <>
                            <option value={e}>
                              Queue {i + 1}-{"Hard Rock"}
                            </option>
                          </>
                        );
                      })}
                </optgroup>
              )}
              {keys && keys.filter((s) => s.split("_")[1] === "Pop-Rock") && (
                <optgroup label={"Pop-Rock"}>
                  {keys &&
                    keys
                      .filter((s) => s.split("_")[1] === "Pop-Rock")
                      .map((e, i) => {
                        return (
                          <>
                            <option value={e}>
                              Queue {i + 1}-{"Pop-Rock"}
                            </option>
                          </>
                        );
                      })}
                </optgroup>
              )}
              {keys && keys.filter((s) => s.split("_")[1] === "Acoustic-Solo") && (
                <optgroup label={"Acoustic-Solo"}>
                  {keys &&
                    keys
                      .filter((s) => s.split("_")[1] === "Acoustic-Solo")
                      .map((e, i) => {
                        return (
                          <>
                            <option value={e}>
                              Queue {i + 1}-{"Acoustic-Solo"}
                            </option>
                          </>
                        );
                      })}
                </optgroup>
              )}
            </select>
          </div>

          <div className="col-md-8">
            <div className="btn btn-primary float-end" onClick={onshowPublish}>
              Publish
            </div>
          </div>
        </div>
        <div className="row">
          {data[selectedkeys] &&
            data[selectedkeys]
              ?.filter((item) => item?.super_judge_selection !== null)
              .sort((a, b) => {
                const RankA = a?.super_judge_selection?.rank;
                const RankB = b?.super_judge_selection?.rank;
                return RankB - RankA;
              })
              .map((e, index) => {
                return (
                  <>
                    <div className="col-12" key={e.id}>
                      <div className="position-relative mb-3">
                        <PlayerIndex
                          img={e?.judge_song?.song?.thumbnail}
                          name={e?.judge_song?.song?.title}
                          genre={e?.judge_song?.song?.genre?.name}
                          event={event}
                          audio={e?.judge_song?.song?.song}
                          PlayingId={index}
                          isPlaying={index === playingIndex}
                          updatePlayingIndex={(i) =>
                            setPlayingIndex(i === playingIndex ? null : i)
                          }
                          setRank={e?.super_judge_selection?.rank}
                          is_published={e?.super_judge_selection?.is_published}
                          islistened={e?.is_played}
                          songId={e?.id}
                          selectedId={e?.super_judge_selection?.id}
                          unselectSong={unselectSong}
                          isPlayedSong={isPlayedSong}
                          isRanking={true}
                          judgeRank={e?.rank}
                          getrank={setRank}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          {data[selectedkeys] &&
            data[selectedkeys]
              ?.filter((item) => item?.super_judge_selection === null)
              .sort((a, b) => {
                const RankA = a?.super_judge_selection?.rank;
                const RankB = b?.super_judge_selection?.rank;
                return RankB - RankA;
              })
              .map((e, index) => {
                return (
                  <>
                    <div className="col-12" key={e.id}>
                      <div className="position-relative mb-3">
                        <PlayerIndex
                          img={e?.judge_song?.song?.thumbnail}
                          name={e?.judge_song?.song?.title}
                          genre={e?.judge_song?.song?.genre?.name}
                          event={event}
                          audio={e?.judge_song?.song?.song}
                          PlayingId={index}
                          isPlaying={index === playingIndex}
                          updatePlayingIndex={(i) =>
                            setPlayingIndex(i === playingIndex ? null : i)
                          }
                          setRank={e?.super_judge_selection?.rank}
                          is_published={e?.super_judge_selection?.is_published}
                          islistened={e?.is_played}
                          songId={e?.id}
                          selectedId={e?.super_judge_selection?.id}
                          unselectSong={unselectSong}
                          isPlayedSong={isPlayedSong}
                          isRanking={true}
                          judgeRank={e?.rank}
                          getrank={setRank}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
        </div>
      </div>

      <Modal show={showPublish} onHide={onHidePublish} size="md">
        <Modal.Header closeButton>
          <div className="text-center">
            <p className="text-center fs-3 fw-light">Disclaimer</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <h3 className="text-center">
              Please be aware that once a song has been published, it cannot be
              edited or modified.!!!
            </h3>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={Publish}>
            Publish it
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JudgesSong;
